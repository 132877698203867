import { useSelector } from 'react-redux'
import { useMount } from 'react-use'

import { fetchPublicWebsite } from 'state/concepts/website/actions'
import { websiteSelector } from 'state/concepts/website/selectors'
import isServicesSectionAvailable from 'utils/website/isServicesSectionAvailable'
import isProvidersSectionAvailable from 'utils/website/isProvidersSectionAvailable'
import redirect from 'utils/redirect'
import { bootWidget } from 'utils/widgets/bootWidget'
import isReviewsSectionAvailable from 'utils/website/isReviewsSectionAvailable'
import { workspaceSelector } from 'state/concepts/subdomain/selectors'
import isLocationsSectionAvailable from 'utils/website/isLocationsSectionAvailable'
import Header from './Header'
import Reviews from './Reviews'
import Footer from './Footer'
import Providers from './Providers'
import Services from './Services'
import Locations from './Locations'
import Meta from './Meta'

const Website = () => {
  const website = useSelector(websiteSelector)
  const showProviders = isProvidersSectionAvailable(website)
  const showReviews = isReviewsSectionAvailable(website)
  const showServices = isServicesSectionAvailable(website)
  const showLocations = isLocationsSectionAvailable(website)

  useMount(() => bootWidget(website, false))

  return (
    <div className="relative">
      <Meta />
      <Header />
      {showProviders && <Providers />}
      {showServices && <Services />}
      {showLocations && <Locations />}
      {showReviews && <Reviews />}
      <Footer />
    </div>
  )
}

Website.getInitialProps = ({ store, res: response }) => {
  const workspace = workspaceSelector(store.getState())

  if (workspace) {
    store.dispatch(fetchPublicWebsite({ response }))
  } else {
    redirect({ href: '/login', response })
  }
}

export default Website
